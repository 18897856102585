import React, { useEffect, useState } from "react";
import assets from "../.././assets";
import "./index.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import { motion } from "framer-motion";
/* Autoplay */
import SwiperCore, { Autoplay, EffectFade } from "swiper";

const Hero = () => {
  SwiperCore.use([Autoplay]);
  SwiperCore.use([EffectFade]);

  const [indexSlider, setIndexSlider] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  useEffect(() => {
    let mobile = window.innerWidth < 850;
    return setIsMobile(mobile);
  }, []);

  const slider = {
    initial: { opacity: 0, x: isMobile ? 200 : 700 },
    show: {
      opacity: 1,
      x: isMobile ? -130 : 30,
      y: isMobile ? -50 : -120,
      minWidth: isMobile ? "230px" : "400px",
    },
    show2: {
      opacity: 1,
      x: isMobile ? -150 : -130,
      minWidth: isMobile ? "350px" : "520px",
    },
    showRotatev1: {
      opacity: 1,
      x: isMobile ? -110 : 30,
      y: isMobile ? 160 : 180,
      rotate: -13,
      minWidth: isMobile ? "270px" : "400px",
    },
  };

  return (
    <div className="hero section">
      {assets.hero.blobFront}
      {assets.hero.blobBack}

      <div className="container-lg relative">
        {assets.adds.migas}
        <div className="flex flexRow flexWrap heroDiv">
          <h1>
            La elección
            <br />
            <span>Saludable</span>
          </h1>
          <Swiper
            className="sliderHero"
            slidesPerView={1}
            modules={[Autoplay, EffectFade]}
            onSlideChange={(swiper) => setIndexSlider(swiper.realIndex)}
            allowTouchMove={false}
            effect={"fade"}
            rewind={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <motion.img
                className="shadowImg"
                src={assets.products.sandwichx3.v1}
                variants={slider}
                initial="initial"
                animate={indexSlider === 0 ? "show" : ""}
                transition={{ delay: 0.5 }}
                alt="Sandwich x3"
              />
              <motion.img
                className="shadowImg"
                src={assets.products.sandwichx3.v2}
                variants={slider}
                initial="initial"
                animate={indexSlider === 0 ? "show2" : ""}
                alt="Sandwich x3"
              />
              <motion.img
                className="shadowImg"
                src={assets.products.sandwichx3.v1}
                variants={slider}
                initial="initial"
                animate={indexSlider === 0 ? "showRotatev1" : ""}
                transition={{ delay: 0.7 }}
                alt="Sandwich x3"
              />
            </SwiperSlide>

            <SwiperSlide>
              <motion.img
                className="shadowImg"
                src={assets.products.salvadofamiliar.v1}
                variants={slider}
                initial="initial"
                animate={indexSlider === 1 ? "show" : ""}
                transition={{ delay: 0.5 }}
                alt="Sandwich x3"
              />
              <motion.img
                className="shadowImg"
                src={assets.products.salvadofamiliar.v2}
                variants={slider}
                initial="initial"
                animate={indexSlider === 1 ? "show2" : ""}
                alt="Sandwich x3"
              />
              <motion.img
                className="shadowImg"
                src={assets.products.salvadofamiliar.v1}
                variants={slider}
                initial="initial"
                animate={indexSlider === 1 ? "showRotatev1" : ""}
                transition={{ delay: 0.7 }}
                alt="Sandwich x3"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Hero;
