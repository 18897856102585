import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import HomeB2C from "./Pages/HomeB2C";
import HomeB2B from "./Pages/HomeB2B";
import Contacto from "./Pages/Contacto";

function RoutesLeiva() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route exact path="/" element={<HomeB2C />} />
      <Route path="/comercial" element={<HomeB2B />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="*" element={<HomeB2C />} />
    </Routes>
  );
}

export default RoutesLeiva;
