import assets from '../.././assets'

const ListProducts = {
    lanzamientos: [
    /* Item 1 */
        {
            title: 'Tostadas de Arroz',
            text: 'Sésamo, Lino y Chía',
            img: assets.products.tostadalinoycia.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        },
        {
            title: 'Sandwich x3',
            text: 'Galletitas crackers x3 paquetes',
            img: assets.products.sandwichx3.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        },
        {
            title: 'Salvado x3',
            text: 'Galletitas crackers x3 paquetes',
            img: assets.products.salvadofamiliar.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        },
    ],
    productos: [
        {
            title: 'Tostadas de Arroz',
            img: assets.products.tostadalinoycia.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        },
        {
            title: 'Sandwich x3',
            img: assets.products.sandwichx3.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        },
        {
            title: 'Salvado x3',
            img: assets.products.salvadofamiliar.v1,
            link: 'https://tienda.galletitasleiva.com.ar'
        }
    ]
}

export default ListProducts