import React from "react";
import { Link } from "react-router-dom";
import assets from "../.././assets";
import logoHeader from "../../assets/logob2b.svg";

import "./index.css";

const Footer = () => {
  return (
    <div className="section footer" id="footer">
      {assets.footer.blobFront}
      {assets.footer.blobBack}
      <div className="container-lg relative flex flexCol textCenter alignCenter">
        <div className="col60 flex flexCol alignCenter">
          <img className="logoFooter" src={logoHeader} alt="Logo" />
          <p className="white">
            Brigadier Juan M. de Rosas 384
            <br />
            (1712) Castelar, Buenos Aires - Argentina
            <br />
            Tel.: 4629-5910 (Líneas Rotativas)
          </p>
          <div className="rs flex flexRow alignCenter">
            <a
              href="https://www.facebook.com/galletitasLeiva/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {assets.rs.fb}
            </a>
            <a
              href="https://www.instagram.com/galletitasleiva"
              target="_blank"
              rel="noopener noreferrer"
            >
              {assets.rs.ig}
            </a>
            {/* <a href="https://www.instagram.com/galletitasleiva" target="_blank" rel="noopener noreferrer">
                        {assets.rs.tw}
                    </a> */}
            <a
              href="https://www.linkedin.com/company/galletitas-leiva"
              target="_blank"
              rel="noopener noreferrer"
            >
              {assets.rs.in}
            </a>
          </div>
          <div className="nav">
            <a href="#inicio">Inicio</a>
            <a href="#nosotros">Nosotros</a>
            <a href="#productos">Productos</a>
            <a
              href="https://tienda.galletitasleiva.com.ar"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tienda
            </a>
            <Link to="/comercial">Comercial</Link>
            <a href="#contacto">Contacto</a>
          </div>
        </div>
      </div>
      <p className="copy white">
        © 2023 Todos los derechos reservados Croni S.A.
      </p>
    </div>
  );
};

export default Footer;
