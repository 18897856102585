import React, { useEffect } from "react";
import { useState, useRef } from "react";
import assets from "../.././assets";
import "./index.css";
import axios from "axios";

const Form = ({ b2b }) => {
  const [isB2B, setIsB2B] = useState(b2b ? true : false);
  const [urlHook, setUrlHook] = useState(
    b2b
      ? "https://hook.us1.make.com/c116jjgavu4tmuumelnxq97emef1un28"
      : "https://hook.us1.make.com/dxr1myaxc9e3tpw1qjwwyu97uk4hnk1g"
  );
  const [data, setData] = useState({
    /*   departamento: "",
    nombre: "",
    email: "",
    mensaje: "", */
  });

  const handleChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    form.current.reset();
    let checkboxes = document.querySelectorAll("input[type=radio]");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
    setData({});
    setUrlHook(
      isB2B
        ? "https://hook.us1.make.com/c116jjgavu4tmuumelnxq97emef1un28"
        : "https://hook.us1.make.com/dxr1myaxc9e3tpw1qjwwyu97uk4hnk1g"
    );
  }, [isB2B]);

  const form = useRef(null);
  const [formLoading, setFormLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    /* form.current.reset(); */
    setFormLoading(true);
    const res = await axios
      .post(urlHook, data, {
        Accept: "application/json",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        console.log(response);
        setFormLoading(false);
        setError(false);
        setIsSubmit(true);
      })
      .catch(function (error) {
        console.error(error.message);
        setError(true);
        setFormLoading(false);
      });
    console.log(res);
  };

  return (
    <div className="section contacto" id="contacto">
      <div className="container-lg relative flex flexCol alignCenter">
        {assets.adds.migasBig}
        <div className="col60 flex flexCol">
          <h2 className="textCenter">Contactate con Leiva</h2>
          {!isSubmit ? (
            <form
              className="controlForm flex flexCol"
              onSubmit={(e) => onSubmit(e)}
              ref={form}
            >
              {b2b ? (
                // B2B Form
                <>
                  <div className="toggleCont">
                    <h4
                      className={!isB2B ? "titleDes" : "titleActive"}
                      onClick={() => setIsB2B(true)}
                    >
                      Quiero comercializar Leiva
                    </h4>
                    <div className="toggle">
                      <button
                        className={!isB2B ? "is-active SlideBtn" : "SlideBtn"}
                        onClick={() => setIsB2B(!isB2B)}
                      >
                        <span className="SlideBtn-knob"></span>
                      </button>
                    </div>
                    <h4
                      className={!isB2B ? "titleActive" : "titleDes"}
                      onClick={() => setIsB2B(false)}
                    >
                      Quiero contactarme con Leiva
                    </h4>
                  </div>
                  {isB2B ? (
                    <>
                      <h4>Selecciona un canal:</h4>
                      <div className="radios flex flexRow gap">
                        <input
                          onChange={handleChangeData}
                          label="Directo"
                          type="radio"
                          name="canal"
                          value="directo"
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="Indirecto"
                          type="radio"
                          name="canal"
                          value="indirecto"
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="Digital"
                          type="radio"
                          name="canal"
                          value="digital"
                          required
                        />
                      </div>
                      <div className="radios flex flexRow gap">
                        {data.canal === "directo" && (
                          <>
                            <input
                              onChange={handleChangeData}
                              label="Autoservicio"
                              type="radio"
                              name="subcanal"
                              value="autoservicio"
                              required
                            />
                            <input
                              onChange={handleChangeData}
                              label="Cadena"
                              type="radio"
                              name="subcanal"
                              value="cadena"
                              required
                            />
                          </>
                        )}
                        {data.canal === "indirecto" && (
                          <>
                            <input
                              onChange={handleChangeData}
                              label="Mayorista"
                              type="radio"
                              name="subcanal"
                              value="mayorista"
                              required
                            />
                            <input
                              onChange={handleChangeData}
                              label="Distribuidor"
                              type="radio"
                              name="subcanal"
                              value="distribuidor"
                              required
                            />
                          </>
                        )}
                      </div>
                      {data.canal !== "digital" && (
                        <>
                          {data.subcanal && (
                            <>
                              <h4>Ingresa tus recursos:</h4>

                              <div className="inputsCanal">
                                <label>
                                  Puntos de venta
                                  <input
                                    onChange={handleChangeData}
                                    type="number"
                                    pattern="^[0-9]+$"
                                    name="puntosdeventa"
                                    label="puntosdeventa"
                                    placeholder="Puntos de Venta"
                                    required
                                  />
                                </label>
                                <label>
                                  Salón de ventas (M2)
                                  <input
                                    onChange={handleChangeData}
                                    type="text"
                                    name="m2salon"
                                    label="m2salon"
                                    placeholder="Salón de ventas (M2)"
                                    required
                                  />
                                </label>
                                {(data.subcanal === "mayorista" ||
                                  data.subcanal === "distribuidor") && (
                                  <label>
                                    Depósito (M2)
                                    <input
                                      onChange={handleChangeData}
                                      type="text"
                                      name="m2deposito"
                                      label="m2deposito"
                                      placeholder="Depósito (M2)"
                                      required
                                    />
                                  </label>
                                )}
                                {data.subcanal === "distribuidor" && (
                                  <>
                                    <label>
                                      Preventistas
                                      <input
                                        onChange={handleChangeData}
                                        type="number"
                                        pattern="^[0-9]+$"
                                        name="preventistas"
                                        label="preventistas"
                                        placeholder="Preventistas"
                                        required
                                      />
                                    </label>
                                    <label>
                                      Vehículos
                                      <input
                                        onChange={handleChangeData}
                                        type="number"
                                        pattern="^[0-9]+$"
                                        name="vehiculos"
                                        label="vehiculos"
                                        placeholder="Vehículos"
                                        required
                                      />
                                    </label>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      <input
                        onChange={handleChangeData}
                        type="text"
                        name="cobertura"
                        id="cobertura"
                        placeholder="Zona de Cobertura"
                        required
                      />
                    </>
                  ) : (
                    <>
                      <h4>Dirigido al departamento:</h4>
                      <div className="radios flex flexRow justifySpace gap">
                        <input
                          onChange={handleChangeData}
                          label="Ventas"
                          type="radio"
                          name="departamento"
                          value="ventas"
                          defaultChecked
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="Compras"
                          type="radio"
                          name="departamento"
                          value="compras"
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="Calidad"
                          type="radio"
                          name="departamento"
                          value="calidad"
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="Información"
                          type="radio"
                          name="departamento"
                          value="info"
                          required
                        />
                        <input
                          onChange={handleChangeData}
                          label="RRHH"
                          type="radio"
                          name="departamento"
                          value="rrhh"
                          required
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h4>Dirigido al departamento:</h4>
                  <div className="radios flex flexRow justifySpace gap">
                    <input
                      onChange={handleChangeData}
                      label="Ventas"
                      type="radio"
                      name="departamento"
                      value="ventas"
                      defaultChecked
                      required
                    />
                    <input
                      onChange={handleChangeData}
                      label="Compras"
                      type="radio"
                      name="departamento"
                      value="compras"
                      required
                    />
                    <input
                      onChange={handleChangeData}
                      label="Calidad"
                      type="radio"
                      name="departamento"
                      value="calidad"
                      required
                    />
                    <input
                      onChange={handleChangeData}
                      label="Información"
                      type="radio"
                      name="departamento"
                      value="info"
                      required
                    />
                    <input
                      onChange={handleChangeData}
                      label="RRHH"
                      type="radio"
                      name="departamento"
                      value="rrhh"
                      required
                    />
                  </div>
                </>
              )}

              <input
                onChange={handleChangeData}
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Tu nombre"
                required
              />
              <input
                onChange={handleChangeData}
                type="email"
                name="email"
                id="email"
                placeholder="Tu Email"
                required
              />
              <input
                onChange={handleChangeData}
                type="phone"
                name="phone"
                id="phone"
                placeholder="Tu Teléfono"
                required
              />
              {(!isB2B || !b2b) && (
                <textarea
                  onChange={handleChangeData}
                  type="textarea"
                  name="mensaje"
                  id="mensaje"
                  placeholder="Tu mensaje"
                  required
                />
              )}

              {error && (
                <span className="error">Hubo un error con el servidor</span>
              )}
              {formLoading ? (
                <span className="loading">Enviando datos...</span>
              ) : (
                <button className="boton" type="submit">
                  Enviar mensaje
                </button>
              )}
            </form>
          ) : (
            <span className="loading success">
              ¡Gracias! Ya recibimos tus datos
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
