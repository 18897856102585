import React from "react";
import HeaderB2B from "../Components/HeaderB2B";
import Form from "../Components/Form";

const Contacto = () => {
  return (
    <>
      <HeaderB2B />
      <Form b2b />
    </>
  );
};

export default Contacto;
