import React, { useState } from "react";
import { Link } from "react-router-dom";
import assets from "../.././assets";
import logoHeader from "../../assets/logob2b.svg";
import "./index.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header">
      <nav className="container-lg">
        <div className="flex flexRow justifySpace">
          <Link to={"/"} className="logoH">
            <img className="logo" src={logoHeader} alt="Logo Header" />
          </Link>
          <div className="mobileHamb" onClick={() => openMenu()}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className={
              menuOpen ? "menu menuActive flex flexCol" : "menu flex flexCol"
            }
          >
            <div className="rs flex flexRow justifyEnd">
              <a
                href="https://www.facebook.com/galletitasLeiva/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.fb}
              </a>
              <a
                href="https://www.instagram.com/galletitasleiva"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.ig}
              </a>
              {/* <a href="https://www.instagram.com/galletitasleiva" target="_blank" rel="noopener noreferrer">
                {assets.rs.tw}
              </a> */}
              <a
                href="https://www.linkedin.com/company/galletitas-leiva"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.in}
              </a>
            </div>
            <div className="nav">
              <a href="#inicio" onClick={() => openMenu()}>
                Inicio
              </a>
              <a href="#nosotros" onClick={() => openMenu()}>
                Nosotros
              </a>
              <a href="#productos" onClick={() => openMenu()}>
                Productos
              </a>
              <a
                href="https://tienda.galletitasleiva.com.ar"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => openMenu()}
              >
                Tienda
              </a>
              <Link to="/comercial" onClick={() => openMenu()}>
                Comercial
              </Link>
              <a href="#contacto" onClick={() => openMenu()}>
                Contacto
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
