import React from "react";
import assets from "../../assets";
import "./index.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
/* Autoplay */
import SwiperCore, { Autoplay, EffectFade, Navigation } from "swiper";
// Images

import hero1 from "../../assets/herob2b/1.jpg";
import hero2 from "../../assets/herob2b/2.jpg";
import hero3 from "../../assets/herob2b/3.jpg";
import hero4 from "../../assets/herob2b/4.jpg";

const index = () => {
  SwiperCore.use([Autoplay, EffectFade]);
  const gallery = [hero1, hero2, hero3, hero4];

  return (
    <div className="heroB2B">
      <div className="container-lg relative">
        {assets.adds.migas}
        <Swiper
          className="heroSlider"
          slidesPerView={1}
          modules={[Autoplay, Navigation, EffectFade]}
          rewind={true}
          effect={"fade"}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
        >
          {gallery.map((gal, index) => (
            <SwiperSlide key={index}>
              <img src={gal} alt={"img" + index} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex flexCol heroDivB2B">
          <h1>
            Comercializá
            <br />
            <span>Leiva</span>
          </h1>
          <p>
            ¡Con Leiva, llevá los mejores productos alimenticios a tus clientes!
            Convertite en nuestro distribuidor hoy y formá parte de nuestra
            familia.
          </p>
          <a className="boton" href={"#contacto"} rel="noopener noreferrer">
            Comenzá hoy
          </a>
        </div>
      </div>
    </div>
  );
};

export default index;
