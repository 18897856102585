import React from "react";
import HeaderB2B from "../Components/HeaderB2B";
import HeroB2B from "../Components/HeroB2B";
import Section3 from "../Components/Section3";
import Section2 from "../Components/Section2";
import Section4 from "../Components/Section4";
import Form from "../Components/Form";

const HomeB2B = () => {
  return (
    <>
      <HeaderB2B />
      <HeroB2B />
      <Section4 b2b />
      <Section3 b2b />
      <Section2 b2b />
      <Form b2b />
    </>
  );
};

export default HomeB2B;
