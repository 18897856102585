import React from "react";
import assets from "../.././assets";
import logoB2B from "../../assets/logob2b.svg";
import { gallery, galleryb2b } from "./gallery";
import "./index.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
/* Autoplay */
import SwiperCore, { Autoplay, Navigation } from "swiper";

const Section2 = ({ b2b }) => {
  SwiperCore.use([Autoplay]);

  return (
    <div
      className={b2b ? "section nosotros b2bnos" : "section nosotros"}
      id="nosotros"
    >
      <div className="container-lg relative flex flexCol alignCenter">
        {assets.adds.migasBig}
        <div className="col60 flex flexCol textCenter alignCenter">
          <h2>Sobre Leiva</h2>
          <p>
            Leiva es una empresa con medio siglo de experiencia en la
            elaboración de alimentos. En la última década hemos dado un giro
            orientado hacia las tendencias globales de alimentación saludable,
            hemos incorporado las últimas tecnologías, fórmulas y recetas para
            la producción de alimentos sanos y de calidad.
          </p>

          <Swiper
            className={b2b ? "galSlider galB2B" : "galSlider"}
            slidesPerView={1}
            modules={[Autoplay, Navigation]}
            rewind={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: true,
            }}
            navigation={{
              nextEl: ".nextSwiperN",
              prevEl: ".prevSwiperN",
            }}
          >
            {b2b
              ? galleryb2b.map((gal, index) => (
                  <SwiperSlide key={index}>
                    <img src={gal} alt={"img" + index} />
                  </SwiperSlide>
                ))
              : gallery.map((gal, index) => (
                  <SwiperSlide key={index}>
                    <img src={gal} alt={"img" + index} />
                  </SwiperSlide>
                ))}
          </Swiper>
          <div className={b2b ? "navSwiper b2bNav" : "navSwiper"}>
            <div className="nextSwiperN">{assets.arrow.right}</div>
            <div className="prevSwiperN">{assets.arrow.left}</div>
          </div>

          <img className="logoSlider" src={logoB2B} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
