import React from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import Section1 from "../Components/Section1";
import Section2 from "../Components/Section2";
import Section3 from "../Components/Section3";
import Section4 from "../Components/Section4";
import Form from "../Components/Form";
import Footer from "../Components/Footer";

const HomeB2C = () => {
  return (
    <>
      <Header />
      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Form />
      <Footer />
    </>
  );
};

export default HomeB2C;
