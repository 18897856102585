import React from "react";
import assets from "../.././assets";
import "./index.css";
import { motion } from "framer-motion";
import logoB2B from "../../assets/logob2b.svg";

const Section3 = ({ b2b }) => {
  const variants = {
    hidden: {
      opacity: 0,
      y: 120,
    },
    /* Col 1 */
    show10: {
      opacity: 1,
      x: 60,
      y: -30,
      rotate: "-70deg",
    },
    show11: {
      opacity: 1,
      x: -130,
      y: -30,
      rotate: "-12deg",
    },
    show12: {
      opacity: 1,
      x: -70,
      y: 40,
    },
    /* Col 2 */

    show20: {
      opacity: 1,
      x: 60,
      y: -80,
      rotate: "16deg",
    },
    show21: {
      opacity: 1,
      x: 0,
      y: 0,
    },

    /* Col 3 */

    show3: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  };

  return (
    <div className={b2b ? "section info b2binfo" : "section info"}>
      <div className="container-lg relative flex flexRow flexWrap justifySpace">
        {assets.adds.migasBig}
        <div className="col50 flex flexCol alignCenter colImg col1">
          {assets.columns.col1.map((item, index) => (
            <motion.img
              src={item}
              className={"shadowImg img1" + index}
              alt={"Col" + index}
              key={index}
              variants={variants}
              initial="hidden"
              whileInView={"show1" + index}
              viewport={{ once: true }}
              transition={{
                delay: index * 0.5 + 0.5,
              }}
              style={{
                zIndex: index,
              }}
            />
          ))}
        </div>
        <div className="col40 flex flexCol vertCenter col2">
          <h2>¿Por qué elegirnos?</h2>
          {b2b ? (
            <p>
              Leiva es una empresa con medio siglo de experiencia en la
              elaboración de alimentos. En la última década hemos dado un giro
              orientado hacia las tendencias globales de alimentación saludable,
              mediante la incorporación de las últimas tecnologías, fórmulas y
              recetas para la producción de alimentos sanos y de calidad.
            </p>
          ) : (
            <p>
              Leiva es una marca con tradición en el mercado de las galletitas,
              que cuenta con una amplia línea de productos saludables y de
              calidad con múltiples presentaciones que van desde envases
              individuales hasta opciones de empaquetado tripack y cuatripack.
            </p>
          )}
        </div>

        <div className="col40 flex flexCol vertCenter col3">
          <h2>La opción saludable</h2>
          {b2b ? (
            <p>
              Sostenemos diariamente la idea de que nuestros productos puedan
              satisfacer a todo tipo de consumidor. Nuestras líneas de productos
              incluyen cereales, harinas integrales y semillas como el sésamo,
              el lino y la chía. Elaboramos alimentos bajos en sodio, libres de
              gluten (aptos para celíacos a base de arroz), vegetarianos y
              veganos.
            </p>
          ) : (
            <p>
              Sostenemos diariamente la idea de que nuestros productos puedan
              satisfacer a todo tipo de consumidor. Nuestras líneas de productos
              incluyen cereales como la chía, el salvado, además elaboramos
              alimentos bajos en sodio, sin TACC aptos para celíacos a base de
              arroz.
            </p>
          )}
        </div>

        <div className="col50 flex flexCol alignCenter colImg col4">
          {assets.columns.col2.map((item, index) => (
            <motion.img
              src={item}
              className={"shadowImg img2" + index}
              alt={"Col" + index}
              key={index}
              variants={variants}
              initial="hidden"
              whileInView={"show2" + index}
              viewport={{ once: true }}
              transition={{
                delay: index * 0.5 + 0.5,
              }}
              style={{
                zIndex: index,
              }}
            />
          ))}
        </div>
        {b2b ? (
          <>
            <div className="col50 flex flexCol alignCenter col5 b2bCol">
              <motion.img
                src={logoB2B}
                className="shadowImg logoCol"
                alt="Col3"
                variants={variants}
                initial="hidden"
                whileInView="show3"
                viewport={{ once: true }}
                transition={{
                  delay: 0.5,
                }}
              />
            </div>
            <div className="col40 flex flexCol vertCenter col6">
              <h2>La marca Leiva</h2>
              <p>
                Somos activos en la comunicación de nuestras propuestas y su
                evolución, puede encontrarnos en medios masivos tradicionales
                como así también en redes sociales y medios digitales. Somos{" "}
                <strong>#buenacompañia</strong> para su negocio.
              </p>
            </div>

            <div className="col40 flex flexCol vertCenter col8">
              <h2>Leiva es servicio</h2>
              <p>
                Nuestra calidad se extiende a poder satisfacer todas las
                inquietudes con respecto a nuestros productos y sus diferentes
                presentaciones. Nuestros clientes cuentan con el asesoramiento
                constante de nuestros equipos de venta, desarrollo y producción
                con el fin de compartir y escuchar para mejorar día a día.
              </p>

              <p>
                Damos abastecimiento sostenido desde nuestras tres Plantas
                industriales ubicadas en la zona oeste del Gran Buenos Aires,
                nos involucramos en la distribución y mantenemos un constante
                desarrollo de producto para abarcar tendencias y necesidades de
                los consumidores.
              </p>
            </div>
            <div className="col50 flex flexCol alignCenter colImg col7">
              <motion.img
                src={assets.columns.col3}
                className="shadowImg img3"
                alt="Col3"
                variants={variants}
                initial="hidden"
                whileInView="show3"
                viewport={{ once: true }}
                transition={{
                  delay: 0.5,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col50 flex flexCol alignCenter colImg col5">
              <motion.img
                src={assets.columns.col3}
                className="shadowImg img3"
                alt="Col3"
                variants={variants}
                initial="hidden"
                whileInView="show3"
                viewport={{ once: true }}
                transition={{
                  delay: 0.5,
                }}
              />
            </div>
            <div className="col40 flex flexCol vertCenter col6">
              <h2>Leiva es servicio</h2>
              <p>
                Nuestra calidad se extiende a la idea de poder satisfacer todas
                las inquietudes con respecto a nuestros productos, sus
                diferentes presentaciones. para esto siempre está disponible el
                asesoramiento constante de nuestro equipo de ventas que está a
                disposición para atenderte de forma online o telefónica.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Section3;
