import React, { useState } from "react";
import { Link } from "react-router-dom";
import assets from "../../assets";
import logoHeader from "../../assets/logob2b.svg";
import "./index.css";

const HeaderB2B = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className="headerB2B">
      <nav className="container-lg">
        <div className="flex flexRow justifySpace">
          <Link to={"/"} className="logoH">
            <img className="logoB2B" src={logoHeader} alt="Logo Leiva" />
          </Link>
          <div
            className={menuOpen ? "mobileHamb hambActive" : "mobileHamb"}
            onClick={() => openMenu()}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className={
              menuOpen ? "menu menuActive flex flexCol" : "menu flex flexCol"
            }
          >
            <div className="rs flex flexRow justifyEnd">
              <a
                href="https://www.facebook.com/galletitasLeiva/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.fb}
              </a>
              <a
                href="https://www.instagram.com/galletitasleiva"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.ig}
              </a>
              {/* <a href="https://www.instagram.com/galletitasleiva" target="_blank" rel="noopener noreferrer">
                {assets.rs.tw}
              </a> */}
              <a
                href="https://www.linkedin.com/company/galletitas-leiva"
                target="_blank"
                rel="noopener noreferrer"
              >
                {assets.rs.in}
              </a>
            </div>
            <div className="nav">
              <Link to="/" onClick={() => openMenu()}>
                Inicio
              </Link>
              <a href="/comercial#catalogo" onClick={() => openMenu()}>
                Catálogo
              </a>
              <a href="/comercial#contacto" onClick={() => openMenu()}>
                Contacto
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderB2B;
