import React from 'react'
import assets from '../.././assets'
import './index.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
/* Autoplay */
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import ListProducts from './ListProducts';


const Section1 = () => {

  SwiperCore.use([Autoplay])


  return (
    <div className='section carruSec noPadBot' id='productos'>            
      <div className="container-lg relative">
        <div className="flex flexRow flexWrap gap justifySpace">
          {assets.adds.migas}
          <div className="col35 relative">
            <h2>Nuevos lanzamientos</h2>
              <Swiper
                className='lanzSlider'
                spaceBetween={20}
                slidesPerView={1}
                modules={[Autoplay, Pagination, Navigation]}
                rewind={true}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: true,
                  }}
                navigation={{
                  nextEl: '.nextSwiper',
                  prevEl: '.prevSwiper',
                }}
              >
              { 
                ListProducts.lanzamientos.map((product, index) => (
                <SwiperSlide className='relative lanzamientos' key={index}>
                    <img className='shadowImg' src={product.img} alt={product.title} />
                    <div className="info flex flexCol selfCenter">
                      <h4>{product.title}</h4>
                      <p>{product.text}</p>
                      <a className='boton' href={product.link} target="_blank" rel="noopener noreferrer">Ver producto</a>
                    </div>
                </SwiperSlide>
                ))
              }
            </Swiper>
            <div className="navSwiper">
              <div className="nextSwiper">{assets.arrow.right}</div>
              <div className="prevSwiper">{assets.arrow.left}</div>
            </div>
          </div>
          <div className="col50 relative">
            <h2>Nuestros productos</h2>
              <Swiper
                  spaceBetween={30}
                  className='prodSlider'
                  slidesPerView={2}
                  modules={[Autoplay, Pagination, Navigation]}
                  loop={true}
                  pagination={{ clickable: true }}
                  autoplay={{
                      delay: 4500,
                      disableOnInteraction: true,
                    }}
                  navigation={{
                    nextEl: '.nextSwiperP',
                    prevEl: '.prevSwiperP',
                  }}
                >
                {
                  ListProducts.productos.map((product, index) => (
                    <SwiperSlide className='relative prodSlide' key={index}>
                      <a href={product.link} target="_blank" rel="noopener noreferrer">
                        <img className='shadowImg' src={product.img} alt={product.title} />
                        <h4>{product.title}</h4>
                      </a>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
              <div className="navSwiper">
                <div className="nextSwiperP">{assets.arrow.right}</div>
                <div className="prevSwiperP">{assets.arrow.left}</div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section1