import React from "react";
import assets from "../.././assets";
import "./index.css";

const Section4 = ({ b2b }) => {
  return (
    <div className="section cta" id={b2b && "catalogo"}>
      <div className="container-lg">
        <div className="ctaSign relative flex flexRow flexWrap justifySpace">
          <div className="col60 flex flexCol">
            <h3>
              {b2b
                ? "Descargá nuestro catálogo"
                : "Visitá nuestra tienda virtual"}
            </h3>
            <p>
              {b2b
                ? "Descubrí nuestra amplia selección de productos Leiva"
                : "Accedé a todos nuestros productos en nuestra nueva tienda virtual. Podés aprovechar nuestros descuentos y promociones exclusivos."}
            </p>
          </div>
          {assets.adds.migas}
          <a
            className="boton"
            href={
              b2b ? assets.catalogo : "https://tienda.galletitasleiva.com.ar"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {b2b ? "Descargar catálogo" : "Visitar tienda virtual"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section4;
