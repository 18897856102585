import gal1 from "../../assets/nosotros/gal1.jpeg";
import gal2 from "../../assets/nosotros/gal2.jpeg";
import gal3 from "../../assets/nosotros/gal3.jpeg";
import gal4 from "../../assets/nosotros/gal4.jpeg";
// B2B
import galb2b1 from "../../assets/b2bgal/gal1.jpg";
import galb2b2 from "../../assets/b2bgal/gal2.jpg";
import galb2b3 from "../../assets/b2bgal/gal3.jpg";
import galb2b4 from "../../assets/b2bgal/gal4.jpg";
import galb2b5 from "../../assets/b2bgal/gal5.jpg";
import galb2b6 from "../../assets/b2bgal/gal6.jpg";
import galb2b7 from "../../assets/b2bgal/gal7.jpg";

const gallery = [gal1, gal2, gal3, gal4];

const galleryb2b = [
  galb2b1,
  galb2b2,
  galb2b3,
  galb2b4,
  galb2b5,
  galb2b6,
  galb2b7,
];

export { gallery, galleryb2b };
